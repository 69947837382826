import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
export default {
  name: 'AssetsDispatchOut',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      applyShow: false,
      applyText: '',
      allocateApplyId: '',
      applyList: []
    };
  },
  methods: {
    onApplyShow() {
      assetsApi.getOutAllocateApplyList({
        projectId: this.projectInfo.dcProjectId
      }).then(res => {
        this.applyList = res.data.data.result || [];
        if (this.applyList.length) {
          this.applyShow = true;
        } else {
          this.$toast('无调拨申请记录');
        }
      });
    },
    onSelect(item) {
      if (!item) {
        return;
      }
      this.allocateApplyId = item.allocateApplyId;
      this.applyText = item.title;
      this.applyShow = false;
    },
    onNext() {
      if (!this.allocateApplyId) {
        this.$toast('请选择调拨申请');
        return;
      }
      this.$router.push({
        name: 'AssetsDispatchOutDetail',
        query: {
          allocateApplyId: this.allocateApplyId
        }
      });
    }
  }
};